import { useState, useEffect } from "react"

import { isIOS, isLandscape, isMobile } from "../helpers/platform"
import { debounce } from "../helpers/functions"

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
    innerWidth: 0,
    innerHeight: 0,
  })

  const getDimensions = () => {
    const innerW = window.innerWidth
    const innerH = window.innerHeight
    let width
    let height

    if (isMobile()) {
      width = window.screen.width
      height = window.screen.height
      if (isLandscape() && isIOS()) {
        width = window.screen.height
        height = window.screen.width
      }
    } else {
      width = innerW
      height = innerH
    }

    return { width, height, innerWidth: innerW, innerHeight: innerH }
  }

  useEffect(() => {
    if (windowSize.width === 0) {
      setWindowSize(getDimensions())
    }
  }, [])

  useEffect(() => {
    const handleWindowSize = () => {
      const dim = getDimensions()
      setWindowSize(dim)
    }

    let debounceWindowSize = debounce(handleWindowSize, 500)
    window.addEventListener("resize", debounceWindowSize)

    return () => {
      window.removeEventListener("resize", debounceWindowSize)
      debounceWindowSize = null
    }
  }, [windowSize])

  return windowSize
}

export default useWindowSize
