import styled from "styled-components"
import { device } from "../../../theme/mediaQueries"

const StyledButton = styled.button`
  align-items: center;
  display: inline-flex;
  height: 50px;
  width: 230px;
  justify-content: center;
  line-height: 1em;
  min-width: 50px;
  padding: 0px;
  text-align: center;
  text-transform: uppercase;
  outline: none;
  border: none;
  margin: 0px auto;
  cursor: pointer;
  background-color: transparent;
  .c-button--text_container {
    width: 100%;
    height: 48px;
    position: relative;
    display: block;
    overflow: hidden;
    pointer-events: none;
  }
  .c-button--text_bg {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    z-index: 2;
    background-color: ${props => props.theme.vars.mustard};
    transition: all 0.5s ease;
    pointer-events: none;
  }
  .c-button--text_bgroll {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    z-index: 1;
    background-color: transparent;
    transition: all 0.5s ease;
    pointer-events: none;
  }
  .c-button--text_text {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    position: absolute;
    width: 100%;
    transition: all 0.5s ease;
    font-family: ${props => props.theme.vars.tradeGot};
    font-size: 14px;
    font-weight: ${props => props.theme.vars.fontBold};
    letter-spacing: 2px;
    pointer-events: none;
  }
  .c-button--text_content {
    color: ${props => props.theme.vars.charade};
  }
  .c-button--text_contentroll {
    position: absolute;
    transform: translate(-50%, -300%);
    opacity: 0;
    color: ${props => props.theme.vars.white};
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: auto;
    width: 100%;
    height: 100%;
  }

  &.is-disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
  &.c-button--text_light {
    .c-button--text_bg {
      background-color: transparent;
      opacity: 0;
    }
    .c-button--text_bgroll {
      opacity: 0;
      background-color: ${props => props.theme.vars.mirage};
    }
    .c-button--text_text {
      color: ${props => props.theme.vars.black};
    }
    .c-button--text_contentroll {
      color: ${props => props.theme.vars.white};
    }
  }
  &.c-button--text_dark {
    border: 1px solid ${props => props.theme.vars.mirage};
    .c-button--text_bg {
      background-color: ${props => props.theme.vars.mirage};
    }
    .c-button--text_bgroll {
      background-color: transparent;
      opacity: 0;
    }
    .c-button--text_text {
      color: ${props => props.theme.vars.white};
    }
    .c-button--text_contentroll {
      color: ${props => props.theme.vars.black};
    }
  }

  @media ${device.sm} {
    width: 280px;
  }

  @media ${device.xl} {
    width: 312px;
    height: 60px;
    .c-button--text_container {
      height: 58px;
    }
  }

  @media ${device.md} {
    border: 1px solid ${props => props.theme.vars.mustard};
    &:hover {
      .c-button--text_bg {
        opacity: 0;
      }
      .c-button--text_content {
        transform: translate(-50%, 200%);
        opacity: 1;
      }
      .c-button--text_contentroll {
        transform: translate(-50%, -50%);
        opacity: 1;
      }
    }
    &.c-button--text_light:hover {
      .c-button--text_bgroll {
        opacity: 1;
      }
    }
    &.c-button--text_dark:hover {
      .c-button--text_bgroll {
        opacity: 1;
      }
    }
  }
`

export default StyledButton
