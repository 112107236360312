import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import StyledButton from "./ButtonText.style"

export const ButtonTextType = {
  regular: "regular",
  dark: "dark",
  light: "light",
}

const ButtonText = ({ children, onClick, themeType, type = "button", className, sendData, url, classTag = "" }) => {
  if (url) {
    return (
      <StyledButton className={`c-button--text c-button--text_${themeType} ${className} ${sendData ? "is-disabled" : ""}`} type={type} onClick={onClick} themeType={themeType}>
        <Link to={url} className={classTag}>
          <div className="c-button--text_container">
            <div className="c-button--text_bg" />
            <div className="c-button--text_bgroll" />
            <p className="c-button--text_contentroll c-button--text_text">{children}</p>
            <p className="c-button--text_content c-button--text_text">{children}</p>
          </div>
        </Link>
      </StyledButton>
    )
  }
  return (
    <StyledButton className={`c-button--text c-button--text_${themeType} ${className} ${classTag} ${sendData ? "is-disabled" : ""}`} type={type} onClick={onClick} themeType={themeType}>
      <div className="c-button--text_container">
        <div className="c-button--text_bg" />
        <div className="c-button--text_bgroll" />
        <p className="c-button--text_contentroll c-button--text_text">{children}</p>
        <p className="c-button--text_content c-button--text_text">{children}</p>
      </div>
    </StyledButton>
  )
}

ButtonText.propTypes = {
  onClick: PropTypes.func,
  themeType: PropTypes.oneOf(["regular", "dark", "light"]),
  className: PropTypes.string,
}

ButtonText.defaultProps = {
  onClick: () => {},
  themeType: ButtonTextType.regular,
  className: "",
}

export default ButtonText
